<template>
  <FormCard v-if="ppl" :config="gen_data" @DataSaved="UpdateData" />
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import { Query } from "@syncfusion/ej2-data";
import FormCard from "../../../common/form/Card";
import GetOptions from "../../../common/form/options";

const units = [
  { value: 1, label: "Kg" },
  { value: 2, label: "gr" },
  { value: 3, label: "Litri" },
  { value: 4, label: "dl" },
  { value: 5, label: "cl" },
  { value: 6, label: "ml" },
];

export default {
  model: {
    prop: "ppl",
    event: "change",
  },
  props: ["ppl", "cur_lang"],
  components: {
    FormCard,
  },
  // data() {
  //   return {
  //     need_nation_options: {},
  //   };
  // },
  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      this.$emit("change", data);
    },
  },
  // created() {
  //   GetOptions({
  //     dm: GetDataManager("structure_production"),
  //     query_cache: "structure_production",
  //     reducer: function (map, obj) {
  //       if (obj.need_nation) {
  //         map.push(obj.id);
  //       }
  //       return map;
  //     },
  //     reducer_cache: "structure_production_need_nation",
  //   }).then((e) => {
  //     this.need_nation_options = e;
  //   });
  // },
  computed: {
    // need_country() {
    //   return this.need_nation_options.length > 0
    //     ? this.need_nation_options.includes(this.ppl.production_type_id)
    //     : false;
    // },

    gen_data() {
      return {
        title: this.$t("role.product.lots.card_price"),
        data: this.ppl,
        fields: {
          pw: {
            title: this.$t("models.lot.pw"),
            type: "CInputRadioGroup",
            description: this.$t("models.lot.pw_info"),
            size: 12,
            subsize: [3, 9],
            options: [
              { value: false, label: this.$t("models.lot.pw_optionF") },
              { value: true, label: this.$t("models.lot.pw_optionT") },
            ],
          },
          price: {
            title: this.$t("models.lot.price"),
            description: this.$t("models.lot.price_info"),
            type: "CInput",
            size: 12,
            subsize: [3, 9],
          },
          unit: {
            title: this.$t("models.lot.unit"),
            description: this.$t("models.lot.unit_info"),
            size: 12,
            subsize: [3, 9],
            hide: true,
            children: {
              price_len: {
                type: "CInput",
                size: 3,
                subsize: [0, 12],
              },
              price_unit: {
                type: "CSelect",
                size: 4,
                options: units,
                subsize: [0, 12],
              },
              price_len_mol: {
                label: this.$t("models.lot.unit_mol"),
                type: "CInputCheckboxB",
                size: 3,
                subsize: [0, 12],
                enabled: !this.ppl.pw,
              },
            },
          },
          pw_m_unit: {
            title: this.$t("models.lot.pw_m_unit"),
            description: this.$t("models.lot.pw_m_unit_info"),
            size: 12,
            subsize: [3, 9],
            hide: true,
            enabled: this.ppl.pw,
            children: {
              pw_len: {
                name: "pw_len",
                type: "CInput",
                size: 3,
                subsize: [0, 12],
              },
              pw_unit: {
                name: "",
                type: "CSelect",
                size: 4,
                options: units,
                subsize: [0, 12],
              },
            },
          },
          price_sugg: {
            title: this.$t("models.lot.price_sugg"),
            description: this.$t("models.lot.price_sugg_info"),
            type: "CInput",
            size: 12,
            subsize: [3, 9],
          },
          tax_rate_id: {
            title: this.$t("models.lot.tax_rate_id"),
            description: this.$t("models.lot.tax_rate_id_info"),
            type: "CSelect",
            size: 12,
            subsize: [3, 9],
            options: GetOptions({
              dm: GetDataManager("geo_tax"),
              query: new Query().where(
                "country_id",
                "equal",
                this.$store.state.role.country_id
              ),
              query_cache: "geo_tax_" + this.$store.state.role.country_id,
            }),
          },
          availability_limitless: {
            title: this.$t("models.lot.w_availability_limitless"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [3, 9],
            options: [
              {
                value: true,
                label: this.$t("models.lot.w_availability_limitless_optionT"),
              },
              {
                value: false,
                label: this.$t("models.lot.w_availability_limitless_optionF"),
              },
            ],
            description: this.$t("models.lot.w_availability_limitless_info"),
          },
          availability_start: {
            title: this.$t("models.lot.w_availability_start"),
            description: this.$t("models.lot.w_availability_start_info"),
            type: "CInput",
            size: 12,
            subsize: [3, 9],
            hide: true,
            enabled: !this.ppl.availability_limitless,
          },
          availability_xd: {
            title: this.$t("models.lot.w_availability_xd"),
            description: this.$t("models.lot.w_availability_xd_info"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [3, 9],
            options: [
              {
                value: true,
                label: this.$t("models.lot.w_availability_xd_optionT"),
              },
              {
                value: false,
                label: this.$t("models.lot.w_availability_xd_optionF"),
              },
            ],
            enabled: !this.ppl.availability_limitless,
          },
          availability_over: {
            title: this.$t("models.lot.w_availability_over"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [3, 9],
            options: [
              {
                value: true,
                label: this.$t("models.lot.w_availability_over_optionT"),
              },
              {
                value: false,
                label: this.$t("models.lot.w_availability_over_optionF"),
              },
            ],
            description: this.$t("models.lot.w_availability_over_info"),
            enabled: !this.ppl.availability_limitless,
          },
          availability: {
            title: this.$t("models.lot.availability"),
            description: this.$t("models.lot.availability_info"),
            type: "CInput",
            size: 12,
            subsize: [3, 9],
            hide: true,
            readonly: true,
            enabled: !this.ppl.availability_limitless,
          },
          valid_from: {
            title: this.$t("models.lot.valid_from"),
            description: this.$t("models.lot.valid_from_info"),
            type: "CDate",
            size: 12,
            subsize: [3, 9],
            hide: true,
          },
          valid_to: {
            title: this.$t("models.lot.valid_to"),
            description: this.$t("models.lot.valid_to_info"),
            type: "CDate",
            size: 12,
            subsize: [3, 9],
            hide: true,
          },
        },
        dm: GetDataManager("role_producer_products_lots", [
          this.$store.state.role.id,
        ]),
      };
    },
  },
};
</script>
